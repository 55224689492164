import { Component } from '@angular/core';
import { BankAccount } from '@uni-entities';
import { OnboardingService } from '../../onboarding-service';
import { ISelectConfig } from '@uni-framework/ui/uniform';
import { UniNumberFormatPipe } from '@uni-framework/pipes/uniNumberFormatPipe';
import { Logger } from '@uni-framework/core/logger';

@Component({
    selector: 'init-sb1-default-bank-account',
    templateUrl: './sb1-default-bank-account.html',
    styleUrls: ['./sb1-default-bank-account.sass'],
    standalone: false,
})
export class SB1DefaultBankAccount {
    standardAccountSelectConfig = <ISelectConfig>{
        template: (account: BankAccount) =>
            `${account.Label} - ${this.numberPipe.transform(+account.AccountNumber, 'bankacct')}`,
        searchable: false,
        placeholder: 'Velg konto',
    };

    constructor(
        public onboardingService: OnboardingService,
        private numberPipe: UniNumberFormatPipe,
        private logger: Logger,
    ) {}

    ngOnInit() {
        this.onboardingService.getExternalBankAccounts();
        this.logger.logPageView('Onboarding - Default bank account');
    }

    selectDefaultBankAccount(account: BankAccount) {
        this.onboardingService.setDefaultBankAccount(account);
        this.onboardingService.guessLedgerAccountNumber();
    }
}
