import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'init-select-company',
    templateUrl: './select-company.html',
    styleUrls: ['./select-company.sass'],
    standalone: false,
})
export class SelectCompany {
    theme = theme.theme;
    themes = THEMES;

    constructor() {}
}
