import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { OnboardingService } from '../../onboarding-service';
import { OnboardingBankServices } from '@app/models';
import { Logger } from '@uni-framework/core/logger';

@Component({
    selector: 'init-sb1-bank-services',
    templateUrl: './sb1-bank-services.html',
    styleUrls: ['./sb1-bank-services.sass'],
    standalone: false,
})
export class SB1BankServices {
    theme = theme.theme;
    themes = THEMES;

    useCustomSetup = JSON.parse(sessionStorage.getItem('USE_CUSTOM_BANK_SETUP')) ?? false;

    state: OnboardingBankServices;

    constructor(
        public onboardingService: OnboardingService,
        private logger: Logger,
    ) {
        this.state = this.onboardingService.state().BankServices;

        if (!this.state) {
            this.state = this.getDefaultState();
            this.onStateChange();
        }

        this.logger.logPageView('Onboarding - Bank services');
    }

    useStandardChange() {
        sessionStorage.setItem('USE_CUSTOM_BANK_SETUP', this.useCustomSetup.toString());
        setTimeout(() => {
            if (!this.useCustomSetup) {
                this.state = this.getDefaultState();
            }
            this.onStateChange();
        });
    }

    onStateChange() {
        setTimeout(() => {
            this.onboardingService.setBankServices(this.state);
            this.onboardingService.setCurrentStepIsValid(
                !this.state.HasStatements || (this.state.HasStatements && !!this.state.BankStatementStartDate),
            );
        });
    }

    getDefaultState() {
        return {
            HasPayments: true,
            HasStatements: true,
            BankStatementStartDate: new Date(),
            HasRecievables: false,
            HasPreApprovedPayment: true,
        };
    }
}
