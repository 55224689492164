import { Component } from '@angular/core';
import { ONBOARDING_STEPS } from '@app/models';
import { OnboardingService } from '../../onboarding-service';
import { Logger } from '@uni-framework/core/logger';

@Component({
    selector: 'init-sb1-existing-reconciliation',
    templateUrl: './sb1-existing-reconciliation.html',
    styleUrls: ['./sb1-existing-reconciliation.sass'],
    standalone: false,
})
export class Sb1ExistingReconciliation {
    hasExistingReconciliation: boolean;

    constructor(
        public onboardingService: OnboardingService,
        private logger: Logger,
    ) {}

    ngOnInit() {
        if (this.onboardingService.state()?.Extras?.hasExistingReconciliation !== undefined) {
            this.hasExistingReconciliation = this.onboardingService.state()?.Extras?.hasExistingReconciliation;
            this.onboardingService.setCurrentStepIsValid(true);
        }

        this.logger.logPageView('Onboarding - Existing reconciliation');
    }

    onSelectionChange() {
        this.onboardingService.setExtras({
            ...this.onboardingService.state().Extras,
            hasExistingReconciliation: this.hasExistingReconciliation,
        });
        this.onboardingService.setCurrentStepIsValid(true);
        this.onboardingService.setStepIsValidByType(ONBOARDING_STEPS.BANK_ACCOUNTS, !this.hasExistingReconciliation);
    }
}
