import { Component, SecurityContext } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { OnboardingService } from '../onboarding-service';
import { ONBOARDING_STEPS } from '@app/models';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@app/authService';
import { Subscription } from 'rxjs';

@Component({
    selector: 'init-validation-error',
    templateUrl: './validation-error.html',
    styleUrls: ['./validation-error.sass'],
    standalone: false,
})
export class InitValidationError {
    publicSettingsSubscription: Subscription;

    appName = theme.appName;
    theme = theme.theme;
    themes = THEMES;

    isDnb = theme.theme === THEMES.EXT02;

    companyName: string;
    validationErrorTexts = [];

    constructor(
        public onboardingService: OnboardingService,
        private authService: AuthService,
        private domSanitizer: DomSanitizer,
    ) {}

    ngOnInit() {
        this.companyName = this.onboardingService.state()?.Customer?.Name;
        this.publicSettingsSubscription = this.authService.publicSettingsLoaded$.subscribe((loaded) => {
            if (loaded && !this.validationErrorTexts?.length) {
                this.setTexts();
            }
        });
    }

    ngOnDestroy() {
        this.publicSettingsSubscription?.unsubscribe();
    }

    setTexts() {
        const bankName =
            this.domSanitizer.sanitize(SecurityContext.HTML, this.authService.publicSettings.BankName) ||
            theme.appProvider;

        this.authService.publicSettings.ValidationMethods?.forEach((method) => {
            if (method.Name === 'BrregRole') {
                this.validationErrorTexts.unshift(
                    `Oppdater Brønnøysundregisteret med en rolle tilknyttet deg, og opprett avtale i <strong>${theme.appName}</strong> på nytt.`,
                    `Be en <strong>annen person</strong> med rolle på foretaket om å etablere avtale i ${theme.appName}.`,
                );
            }

            if (method.Name === 'BankAccess') {
                this.validationErrorTexts.push(
                    `Oppdater kundeforholdet i ${bankName} slik at du får en rolle i banken, og opprett avtale i <strong>${theme.appName}</strong> på nytt.`,
                    `Dersom foretaket ikke er kunde i ${bankName}, søk om å etablere kundeforhold.`,
                );
            }
        });
    }

    backToStart() {
        this.onboardingService.clearSessionStorage(); // do we need this?
        this.onboardingService.set(null);
        this.onboardingService.setSelectCompanyState(null);
        this.onboardingService.clearBankAccounts();
        this.onboardingService.setStepIsValidByType(ONBOARDING_STEPS.SELECT_COMPANY, false);
        this.onboardingService.setCurrentStepByType(ONBOARDING_STEPS.SELECT_COMPANY);
    }
}
