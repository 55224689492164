import { Component } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { AuthService } from '@app/authService';
import { Subscription } from 'rxjs';
import { InitService } from '@app/services/common/initService';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ONBOARDING_STEPS, TaxMandatoryType } from '@app/models';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';
import { COMPANY_CREATION_STEPS } from '@app/components/common/company-creation-wizard/company-creation-steps.enum';

@Component({
    selector: 'init-dnb-confirm-and-create',
    templateUrl: './dnb-confirm-and-create.html',
    styleUrls: ['./dnb-confirm-and-create.sass'],
    standalone: false,
})
export class DnbConfirmAndCreate {
    contactFormSubscription: Subscription;
    companyInfoFormSubscription: Subscription;

    contactForm = new UntypedFormGroup(
        {
            ContactEmail: new UntypedFormControl('', [Validators.email, Validators.required]),
            ContactPerson: new UntypedFormControl('', Validators.required),
            ContactPhone: new UntypedFormControl(''),
        },
        { updateOn: 'blur' },
    );

    companyInfoForm = new UntypedFormGroup(
        {
            CompanyName: new UntypedFormControl('', Validators.required),
            OrganizationNumber: new UntypedFormControl('', Validators.required),
            Address: new UntypedFormControl('', Validators.required),
            PostalCode: new UntypedFormControl('', Validators.required),
            City: new UntypedFormControl('', Validators.required),
            Country: new UntypedFormControl(''),
        },
        { updateOn: 'blur' },
    );

    editMode = false;

    taxType = TaxMandatoryType;
    selectedTaxType: TaxMandatoryType;

    constructor(
        public onboardingService: OnboardingService,
        public authService: AuthService,
        private initService: InitService,
        private celebrusService: CelebrusService,
    ) {}

    ngOnInit() {
        this.loadFormInfo();
        this.contactFormSubscription = this.contactForm.valueChanges.subscribe(() => this.setContactInfo());
        this.companyInfoFormSubscription = this.companyInfoForm.valueChanges.subscribe(() => this.setCompanyInfo());
        this.celebrusService.logCompanyCreationStep(COMPANY_CREATION_STEPS.BANKID_STEP2);
    }

    ngOnDestroy() {
        this.contactFormSubscription?.unsubscribe();
        this.companyInfoFormSubscription?.unsubscribe();
    }

    loadFormInfo() {
        const customer = this.onboardingService.state()?.Customer;
        if (customer?.Name || customer?.OrgNumber) {
            this.companyInfoForm.patchValue({
                CompanyName: customer.Name,
                OrganizationNumber: customer.OrgNumber,
                Address: customer.CustomerAddress?.AddressLine1,
                PostalCode: customer.CustomerAddress?.PostalCode,
                City: customer.CustomerAddress?.City,
                Country: customer.CustomerAddress?.Country,
            });

            this.companyInfoForm.markAllAsTouched();

            if (!this.companyInfoForm.valid) {
                this.editMode = true;
            }
        } else {
            setTimeout(() => {
                this.onboardingService.setCurrentStepIsValid(false);
                this.onboardingService.setCurrentStepByType(ONBOARDING_STEPS.SELECT_COMPANY);
            });
            return;
        }

        if (this.onboardingService.state()?.CompanySettings?.TaxMandatoryType) {
            this.selectedTaxType = this.onboardingService.state()?.CompanySettings?.TaxMandatoryType;
        } else {
            this.selectedTaxType = TaxMandatoryType.TaxMandatory;
            this.taxTypeSelected();
        }

        if (customer?.ContactPerson && customer?.ContactEmail) {
            this.contactForm.patchValue({
                ContactEmail: customer.ContactEmail,
                ContactPhone: customer.ContactPhone || '',
                ContactPerson: customer.ContactPerson,
            });

            this.contactForm.markAllAsTouched();
            this.setCurrentStepIsValid();

            if (!this.contactForm.valid) {
                this.editMode = true;
            }
            return;
        }

        this.initService.getCurrentUserInfo().subscribe({
            next: (res) => {
                if (res) {
                    let phoneNumber = res.authPhoneNumber;
                    // split on hyphen if it includes country codes, i.e '47-13371337'
                    if (phoneNumber?.includes('-')) {
                        phoneNumber = phoneNumber.split('-')[1];
                    }

                    this.contactForm.patchValue({
                        ContactEmail: res.email,
                        ContactPhone: phoneNumber || '',
                        ContactPerson: res.fullName || res.userName,
                    });

                    this.contactForm.markAllAsTouched();

                    if (!this.contactForm.valid) {
                        this.editMode = true;
                    }
                    this.setContactInfo();
                } else {
                    this.setContactInfoBasedOnToken();
                }
            },
            error: (err) => {
                this.setContactInfoBasedOnToken();
            },
        });
    }

    setContactInfoBasedOnToken() {
        const token = this.authService.decodedToken();
        this.contactForm.patchValue({
            ContactEmail: token.email,
            ContactPhone: '',
            ContactPerson: token.name,
        });

        this.contactForm.markAllAsTouched();

        if (!this.contactForm.valid) {
            this.editMode = true;
        }
        this.setContactInfo();
    }

    setContactInfo() {
        if (!this.contactForm.valid) {
            this.onboardingService.setCurrentStepIsValid(false);
            return;
        }

        const form = this.contactForm.value;
        this.onboardingService.setCustomer({
            ...(this.onboardingService.state()?.Customer ?? {}),
            ContactEmail: form.ContactEmail,
            ContactPhone: form.ContactPhone,
            ContactPerson: form.ContactPerson,
        });

        this.setCurrentStepIsValid();
    }

    setCompanyInfo() {
        if (!this.companyInfoForm.valid) {
            this.onboardingService.setCurrentStepIsValid(false);
            return;
        }

        const form = this.companyInfoForm.value;
        this.onboardingService.setCustomer({
            ...(this.onboardingService.state()?.Customer ?? {}),
            Name: form.CompanyName,
            OrgNumber: form.OrganizationNumber,
            CustomerAddress: {
                ...(this.onboardingService.state()?.Customer?.CustomerAddress ?? {}),
                AddressLine1: form.Address,
                PostalCode: form.PostalCode,
                City: form.City,
                Country: form.Country,
            },
        });

        this.setCurrentStepIsValid();
    }

    setCurrentStepIsValid() {
        this.onboardingService.setCurrentStepIsValid(this.companyInfoForm.valid && this.contactForm.valid);
    }

    taxTypeSelected() {
        this.onboardingService.setTaxMandatoryType(this.selectedTaxType);
    }
}
