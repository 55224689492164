import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';
import { COMPANY_CREATION_STEPS } from '@app/components/common/company-creation-wizard/company-creation-steps.enum';

@Component({
    selector: 'init-dnb-select-company',
    templateUrl: './dnb-select-company.html',
    styleUrls: ['./dnb-select-company.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class DnbSelectCompany {
    config: AutocompleteOptions = {
        autofocus: true,
        placeholder: 'Hent selskapsdetaljer fra Brønnøysundregistrene',
        lookup: (value) => this.onboardingService.searchBrreg(value),
        resultTableColumns: [
            { header: 'Navn', field: 'navn' },
            { header: 'Organisasjonsnummer', field: 'organisasjonsnummer' },
        ],
    };

    constructor(
        public onboardingService: OnboardingService,
        private cdr: ChangeDetectorRef,
        private celebrusService: CelebrusService,
    ) {}

    ngOnInit() {
        this.init();
    }

    async init() {
        await this.onboardingService.initSelectCompanyStep();
        this.celebrusService.logCompanyCreationStep(COMPANY_CREATION_STEPS.BANKID_STEP1);
    }

    setCustomerAndState(company: any, toastIfNull = false) {
        if (company) {
            this.onboardingService.updateSelectCompanyState('missingOrgNumber', false);
        } else {
            this.onboardingService.updateSelectCompanyState('selectedEndUserAgreement', null);
        }

        // this.onboardingService.clearBankAccounts();
        this.onboardingService.onBrregCompanySelected(company, toastIfNull);
        this.setStepValid(!!company);
        this.cdr.markForCheck();
    }

    setStepValid(isValid: boolean) {
        this.onboardingService.setCurrentStepIsValid(isValid);
    }
}
