import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { ElsaCustomerPartial } from '@app/models';

@Component({
    selector: 'init-eika-select-company',
    templateUrl: './eika-select-company.html',
    styleUrls: ['./eika-select-company.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class EikaSelectCompany {
    config: AutocompleteOptions = {
        autofocus: true,
        placeholder: 'Søk...',
        lookup: (value) => this.onboardingService.searchBrreg(value),
        resultTableColumns: [
            { header: 'Navn', field: 'navn' },
            { header: 'Organisasjonsnummer', field: 'organisasjonsnummer' },
        ],
    };

    companyNameMissingOrgNr: string;

    selectedEndUserAgreementID: string;

    constructor(
        public onboardingService: OnboardingService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.init();
    }

    async init() {
        await this.onboardingService.initSelectCompanyStep();
        if (this.onboardingService.selectCompanyState()?.missingOrgNumber) {
            this.companyNameMissingOrgNr = this.onboardingService.state()?.Customer?.Name || '';
        }
        this.selectedEndUserAgreementID =
            this.onboardingService.selectCompanyState()?.selectedEndUserAgreement?.AgreementID;
    }

    setStepValid(isValid: boolean) {
        this.onboardingService.setCurrentStepIsValid(isValid);
    }

    changeView() {
        this.onboardingService.updateSelectCompanyState(
            'viewMode',
            this.onboardingService.selectCompanyState().viewMode === 1 ? 2 : 1,
        );
        this.cdr.markForCheck();
    }

    // Fetches company from Brønnøysund when user selects an agreement from radio button list
    onAgreementSelected() {
        const selectedAgreement = this.onboardingService
            .selectCompanyState()
            .endUserAgreements.find((a) => a.AgreementID === this.selectedEndUserAgreementID);

        this.onboardingService.selectCompanyState().selectedEndUserAgreement = selectedAgreement;

        const orgNumber = selectedAgreement.OrganizationNumber;
        this.onboardingService.searchBrreg(orgNumber).subscribe({
            next: (companies) => this.setCustomerAndState(companies && companies[0], true),
            error: (err) => console.error(err), // what should happen if we can't find the company in brreg?
        });
    }

    setCustomerAndState(company: any, toastIfNull = false) {
        if (company) {
            this.onboardingService.updateSelectCompanyState('missingOrgNumber', false);
        } else {
            this.onboardingService.updateSelectCompanyState('selectedEndUserAgreement', null);
        }

        this.onboardingService.clearBankAccounts();
        this.onboardingService.onBrregCompanySelected(company, toastIfNull);
        this.setStepValid(!!company);
        this.cdr.markForCheck();
    }

    setCompanyNameMissingOrgNr() {
        if (!this.companyNameMissingOrgNr?.length) {
            this.onboardingService.setCustomer(null);
            this.setStepValid(false);
            return;
        }

        const customer: ElsaCustomerPartial = {
            Name: this.companyNameMissingOrgNr,
            Contracts: [
                {
                    CompanyLicenses: [
                        {
                            CompanyName: this.companyNameMissingOrgNr,
                        },
                    ],
                },
            ],
        };

        // should we reset companysettings?

        this.onboardingService.setCustomer(customer);
        this.setStepValid(true);
    }
}
