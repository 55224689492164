import { Component, computed } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { Logger } from '@uni-framework/core/logger';

@Component({
    selector: 'init-sb1-select-company',
    templateUrl: './sb1-select-company.html',
    styleUrls: ['./sb1-select-company.sass'],
    standalone: false,
})
export class Sb1SelectCompany {
    state = computed(() => this.onboardingService.selectCompanyState());

    config: AutocompleteOptions = {
        autofocus: true,
        placeholder: 'Søk...',
        lookup: (value) => this.onboardingService.searchBrreg(value),
        resultTableColumns: [
            { header: 'Navn', field: 'navn' },
            { header: 'Organisasjonsnummer', field: 'organisasjonsnummer' },
        ],
    };

    companyNameMissingOrgNr: string;
    selectedEndUserAgreementID: string;

    constructor(
        public onboardingService: OnboardingService,
        private logger: Logger,
    ) {}

    ngOnInit() {
        this.init();
    }

    async init() {
        await this.onboardingService.initSelectCompanyStep();
        if (this.state().missingOrgNumber) {
            this.companyNameMissingOrgNr = this.onboardingService.state()?.Customer?.Name || '';
        }
        this.selectedEndUserAgreementID = this.state().selectedEndUserAgreement?.AgreementID;
        this.logger.logPageView('Onboarding - Select company');
    }

    changeView() {
        this.onboardingService.updateSelectCompanyState('viewMode', this.state().viewMode === 1 ? 2 : 1);
    }

    // Fetches company from Brønnøysund when user selects an agreement from radio button list
    onAgreementSelected() {
        const agreements = this.state().endUserAgreements;
        const agreement = agreements.find((a) => a.AgreementID === this.selectedEndUserAgreementID);

        this.onboardingService.updateSelectCompanyState('selectedEndUserAgreement', agreement);

        this.onboardingService.searchBrreg(agreement.OrganizationNumber).subscribe({
            next: (companies) => this.setCompanyData(companies && companies[0], true),
            error: (err) => {
                console.error(err);
                this.setCompanyData(null, true);
            },
        });
    }

    setCompanyData(company: any, toastIfNull = false) {
        this.onboardingService.clearBankAccounts();
        this.onboardingService.onBrregCompanySelected(company, toastIfNull);
        this.onboardingService.setCurrentStepIsValid(!!company);
    }
}
