import { Component } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { Logger } from '@uni-framework/core/logger';

@Component({
    selector: 'init-sb1-bank-accounts',
    templateUrl: './sb1-bank-accounts.html',
    styleUrls: ['./sb1-bank-accounts.sass'],
    standalone: false,
})
export class SB1BankAccounts {
    hasVisitedBankAccountsStep = false;
    bankAccountsEditRowIndex = -1;
    bankAccountsErrorMessage: string;
    ledgerAccountNumberInput: number;

    constructor(
        public onboardingService: OnboardingService,
        private logger: Logger,
    ) {}

    ngOnInit() {
        this.onboardingService.getExternalBankAccounts();
        sessionStorage.setItem('HAS_VISITED_BANK_ACCOUNTS', 'true');
        this.logger.logPageView('Onboarding - Bank accounts');
    }

    goNext() {
        if (this.bankAccountsEditRowIndex !== -1) {
            this.bankAccountsErrorMessage = 'Ikke lagret';
            this.onboardingService.setCurrentStepIsValid(false);
            return;
        }

        const accountWithEmptyLedgerAccountIndex = this.onboardingService
            .bankAccountsState()
            .bankAccounts.findIndex((acc) => !acc.Account.AccountNumber);
        if (accountWithEmptyLedgerAccountIndex !== -1) {
            this.ledgerAccountNumberInput =
                this.onboardingService.bankAccountsState().bankAccounts[
                    accountWithEmptyLedgerAccountIndex
                ].Account?.AccountNumber;
            this.bankAccountsEditRowIndex = accountWithEmptyLedgerAccountIndex;
            this.bankAccountsErrorMessage = 'Må være fire siffer';
        }

        if (this.bankAccountsErrorMessage?.length > 0) {
            this.onboardingService.setCurrentStepIsValid(false);
            return;
        }

        this.onboardingService.setCurrentStepIsValid(true);

        // store in state?
        this.hasVisitedBankAccountsStep = true;

        this.onboardingService.goNext();
    }
}
