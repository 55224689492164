import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BankAccount } from '@uni-entities';
import { OnboardingService } from '../../onboarding-service';

@Component({
    selector: 'init-bank-accounts-table',
    templateUrl: './bank-accounts-table.html',
    styleUrls: ['./bank-accounts-table.sass'],
    standalone: false,
})
export class InitBankAccountsTable {
    @Input() bankAccounts: BankAccount[] = [];
    @Input() editable = false;
    @Input() editAccountRowIndex = -1;
    @Input() errorMessage = '';
    @Input() ledgerAccountNumberInput: number = undefined;

    @Output() editAccountRowIndexChange = new EventEmitter<number>();
    @Output() errorMessageChange = new EventEmitter<string>();
    @Output() ledgerAccountNumberInputChange = new EventEmitter<number>();

    constructor(private onboardingService: OnboardingService) {}

    editLedgerAccountNumber(account: BankAccount, index: number) {
        // be aware that account.AccountNumber is different from account.Account.AccountNumber
        this.ledgerAccountNumberInputChange.emit(account.Account.AccountNumber);
        this.editAccountRowIndexChange.emit(index);
    }

    setGeneralLedgerAccount(account: BankAccount) {
        if (this.ledgerAccountNumberInput?.toString()?.length !== 4) {
            this.errorMessageChange.emit('Må være fire siffer');
            return;
        }

        const ledgerAccountTaken = this.bankAccounts.some(
            (acc) =>
                acc.Account.AccountNumber === this.ledgerAccountNumberInput &&
                acc.AccountNumber !== account.AccountNumber,
        );
        if (ledgerAccountTaken) {
            this.errorMessageChange.emit(
                'Det finnes allerede en hovedbokskonto med dette nummeret. Benytt et annet nummer.',
            );
            return;
        }

        account.Account.AccountNumber = this.ledgerAccountNumberInput;
        this.onboardingService.updateBankAccountsState('bankAccounts', this.bankAccounts);
        this.ledgerAccountNumberInputChange.emit(undefined);
        this.editAccountRowIndexChange.emit(-1);
        this.errorMessageChange.emit('');
    }
}
