import { Component, HostBinding } from '@angular/core';
import { AuthService } from '@app/authService';
import { Router, ActivatedRoute } from '@angular/router';
import { theme, THEMES } from 'src/themes/theme';
import { Subscription, forkJoin } from 'rxjs';
import { ContractType, ONBOARDING_STEPS } from '@app/models';
import { OnboardingService } from './onboarding-service';
import { ElsaCustomersService } from '@app/services/elsa/elsaCustomersService';
import { InitService } from '@app/services/common/initService';

@Component({
    selector: 'onboarding-wrapper',
    templateUrl: './onboarding-wrapper.html',
    styleUrls: ['./onboarding-wrapper.sass'],
    standalone: false,
})
export class OnboardingWrapper {
    // class is defined in _init.sass
    @HostBinding('class.eika-onboarding') isEika = theme.theme === THEMES.EIKA;
    @HostBinding('class.sb1-onboarding') isSB1 = theme.theme === THEMES.SR;
    @HostBinding('class.dnb-onboarding') isDNB = theme.theme === THEMES.EXT02;

    routeSubscription: Subscription;
    tokenSubscription: Subscription;

    busy = false;

    theme = theme.theme;
    themes = THEMES;

    // used in the template
    stepType = ONBOARDING_STEPS;

    nextButtonLabel = theme.theme === THEMES.SR ? 'Gå videre' : 'Neste';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        public onboardingService: OnboardingService,
        private elsaCustomerService: ElsaCustomersService,
        private initService: InitService,
    ) {}

    ngOnInit() {
        // needed incase we get logged out during the onboarding
        // cannot use this alone instead of isAuthenticated, since everything inside this executes every time the token refreshes (silent renew)
        this.tokenSubscription = this.authService.token$.subscribe((token) => {
            if (!token) {
                this.router.navigateByUrl('/init/login');
            }
        });

        const authenticateAndInit = async () => {
            const authenticatedUser = await this.authService.isAuthenticated();
            // we don't have auth guard on init routes, so we need to check here
            if (!authenticatedUser) {
                this.router.navigateByUrl('/init/login');
                return;
            }

            if (
                JSON.parse(sessionStorage.getItem('HAS_ASKED_EXISTING_CUSTOMER')) ||
                this.router.url.includes('contract-activation')
            ) {
                this.init();
            } else {
                this.busy = true;
                forkJoin([
                    this.elsaCustomerService.getAllManaged(authenticatedUser.profile.sub),
                    this.initService.getCompanies(),
                ]).subscribe({
                    next: ([customers, companies]) => {
                        if (customers?.length && companies?.length) {
                            this.onboardingService.isExistingCustomer = true;
                            sessionStorage.setItem('HAS_ASKED_EXISTING_CUSTOMER', 'true');
                        }
                        this.busy = false;
                        this.init();
                    },
                    error: () => {
                        this.busy = false;
                        this.init();
                    },
                });
            }
        };
        authenticateAndInit();
    }

    init() {
        this.onboardingService.buildSteps();

        this.routeSubscription = this.route.queryParamMap.subscribe((params) => {
            const step = params.get('step');
            this.onboardingService.orgnrFromParams = params.get('orgno');
            this.onboardingService.isProspect = !!params.get('isprospect');
            this.onboardingService.campaignCode = params.get('campaignCode');

            const selectedCompanyType = params.get('type') || params.get('companyType') || undefined;
            if (selectedCompanyType === 'demo') {
                // skip everything and go straight to create demo
                this.onboardingService.isDemoFlow = true;

                // unfortunate environment check here
                // add some kind of flag for 'is create demo step' on the step model?
                // or do 'get the last step that is not company_created'?
                // or build a whole different steps array for demo flow?
                const createDemoStep = this.isDNB
                    ? ONBOARDING_STEPS.BANK_SERVICES
                    : ONBOARDING_STEPS.CONFIRM_AND_CREATE;
                this.onboardingService.setCurrentStepByType(createDemoStep);
                return;
            }

            const contractTypeParam = params.get('contractType');
            // undefined means contractType not set, or is an invalid contractType
            if (ContractType[contractTypeParam] === undefined) {
                if (this.onboardingService.selectedContractType !== undefined) {
                    // should mean someone tried to manually provide an invalid type in the url
                    // don't do anything if the service has a valid type set, the params will revert next time they are updated
                    return;
                }
                this.onboardingService.getContractTypes();
                this.onboardingService.selectedContractType = undefined;
                this.onboardingService.setStepIsValidByType(ONBOARDING_STEPS.SELECT_CONTRACTTYPE, false);
                this.onboardingService.setCurrentStepByType(ONBOARDING_STEPS.SELECT_CONTRACTTYPE);
                return;
            }

            if (this.onboardingService.selectedContractType !== +contractTypeParam) {
                this.onboardingService.setSelectedContractType(+contractTypeParam);
            }
            this.onboardingService.setCurrentStep(+step);
        });
    }

    ngOnDestroy() {
        this.routeSubscription?.unsubscribe();
        this.tokenSubscription?.unsubscribe();
    }
}
